import axios from "axios";
import { backendUrl } from "../configs/processEnv";

export const { request } = axios.create({
  baseURL: backendUrl,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

const requestRoot = axios.create({
  baseURL: backendUrl.replace('/api/', ''),
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

export {requestRoot};
