import { createSlice } from "@reduxjs/toolkit";

const initialValue = [];

export const previewReducer = createSlice(
    {
      name: 'previewImg',
      initialState: initialValue,
      reducers: {
        setPreviewImg: (state, action) => {
            const value = action.payload;
            state = value;
            return state;
        }
      }
    }
)

export const { setPreviewImg } = previewReducer.actions;

export const getPreviewImg = state => {
    return state.editorPreview;
}

export default previewReducer;
