import { createSlice } from "@reduxjs/toolkit";
import { request } from "../api/api";

const initialValue = {};

const articleGroupSlice = createSlice({
  name: 'articleGroup',
  initialState: initialValue,
  reducers: {
    setArticleGroup: (state, action) => {
      return {...state, ...action.payload}
    }
  }
})

export default articleGroupSlice;

const { setArticleGroup } = articleGroupSlice.actions;

export const getArticleGroup = (state) => state.articleGroup;

export const loadArticleGroup = () => (dispatch) => {
  request({
    method: 'GET',
    url: 'post/group/'
  })
  .then((res) => {
    dispatch(setArticleGroup(res.data))
  })
  .catch()
}
