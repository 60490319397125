export const LOCAL_STORAGE_KEY = {
  VIEWED_POST: "viewedPosts",
  DATE_SHOW_NOTIFICATION: "dateShowNotification",
  PROFILE_AUTH: "porfile_auth",
};

export const isDraft = process.env.REACT_APP_VISIBLE_DRAFT === "true";

export const URL_PARAMS_NAME = {
  PLACES: "places",
}

export const BLOCKS_ID = {
  DRIVE_AND_DISCOVER: "driveAndDiscover"
}

/**
 * Интервал напоминания о непрочитанных уведомлениях / акциях профиля, в секундах.
 */
export const NOTIFICATIONS_CHECK_INTERVAL = 600;
