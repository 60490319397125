import { useEffect, useState } from "react";
import { LOCAL_STORAGE_KEY } from "../configs/global";

export const setStorageProfileAuth = (value) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.PROFILE_AUTH, value);
  window.dispatchEvent(new StorageEvent("storage"));
}

const useCheckAuthorization = () => {
  const [isAuth, setIsAuth] = useState(localStorage.getItem(LOCAL_STORAGE_KEY.PROFILE_AUTH) === "true");

  useEffect(() => {
    const changeState = () => {
      const isPorfileAuth = localStorage.getItem(LOCAL_STORAGE_KEY.PROFILE_AUTH) === "true";

      if (isAuth !== isPorfileAuth) {
        setIsAuth(isPorfileAuth);
      }
    }
    window.addEventListener("storage", changeState);
    return () => {
      window.removeEventListener("storage", changeState);
    }
  }, [isAuth]);

  return isAuth;
}

export default useCheckAuthorization;
