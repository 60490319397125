import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import LanguageProvider from "./contexts/LanguageContext";
import Routes from "./Routes";
import {getToken, profileInformation} from "./api/profileApi";
import { useDispatch, useSelector } from "react-redux";
import { logoutProfile, setPorfileData, setProfileLoaded } from "./features/profileDataSlice";
import { loadEvents } from "./features/profileEventsSlice";
import { loadNotifications } from "./features/profileNotificationsSlice";
import useCheckAuthorization, { setStorageProfileAuth } from "./hooks/useStorageProfile";
import { regions } from "./content/regions.json";

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import './App.scss';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // регион из url формата /[region]/url или /[year]/[region]/url
  const value = (location.pathname.match(/^\/(\d+\/)?(\w+)/) ?? [])[2];
  const region = regions.includes(value) ? value : null;
  const authorized = useCheckAuthorization(); // флаг авторизованного пользователя из local-storage
  const profileLoaded = useSelector(state => state.profile.isLoaded);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    if (!profileLoaded && !isRendered) {
      // загрузка профиля, акций и уведомлений
      getToken()
        .then(() => {
          profileInformation()
            .then(({ data }) => {
              dispatch(setPorfileData(data));
              dispatch(setProfileLoaded(true))
              setStorageProfileAuth(true);
              setIsRendered(true);
            })
            .catch((err) => {
              if (err.response.code === 401) dispatch(logoutProfile());
              setIsRendered(true);
            })
        })
        .catch(() => {
          setIsRendered(true);
        });
    }
  }, [dispatch, profileLoaded, isRendered]);

  useEffect(() => {
    if (!profileLoaded || !region) return;

    dispatch(loadEvents(region, 'new'));
    dispatch(loadNotifications(region));
  }, [region, profileLoaded, dispatch]);

  useEffect(() => {
    if (!region && authorized) {
      // выход из аккаунта при смене региона и на страницах без него
      dispatch(logoutProfile());
    }
  }, [authorized, dispatch, region]);

  if (!isRendered) {
    return (
      <div className="preload-logo">
        <img src="/assets/images/svg/logo.svg" alt="logo" />
      </div>
    )
  };

  return (
    <LanguageProvider>
      <Routes />
    </LanguageProvider>
  );
}

export default App;
