import { createSlice } from "@reduxjs/toolkit";
import { loadItems, markRead } from "../api/profileNotificationsApi";
import { NOTIFICATIONS_CHECK_INTERVAL as interval } from "../configs/global";

const initialState = {
  isLoaded: false,
  isExistNew: 0,
  items: [],
  total: 0,
  // timestamp на котором сработает напоминание о непрочитанных уведомлениях
  remind: Date.now() + interval * 1000,
};

const profileNotificationsSlice = createSlice({
  name: 'profileNotifications',
  initialState,
  reducers: {
    setItems: (state, action) => {
      const { items, total, isExistNew } = action.payload;

      if (!Array.isArray(items)) return state;

      return {
        isLoaded: true,
        isExistNew,
        items,
        total,
        remind: Date.now() + interval * 1000,
      };
    },
    appendItems: (state, action) => {
      if (!Array.isArray(action.payload)) return state;

      return {
        ...state,
        items: state.items.concat(action.payload),
        remind: Date.now() + interval * 1000,
      };
    },
    setLoaded: (state, action) => {
      return {
        ...state,
        isLoaded: action.payload,
      }
    },
    setReadItem: (state, action) => {
      const decreaseExistNew = state.isExistNew - 1;
      return {
        ...state,
        ...action.payload,
        isExistNew: decreaseExistNew > 0 ? decreaseExistNew : 0,
      }
    },
    deferRemind: (state) => {
      return {
        ...state,
        remind: Date.now() + interval * 1000,
      };
    },
  },
});

const { setItems, appendItems, setLoaded, setReadItem, deferRemind } = profileNotificationsSlice.actions;

/**
 * Загружает новые `.items`, заменяет текущие.
 * @param {string} region - регион сайта
 */
const loadNotifications = (region) => {
  return async (dispatch) => {
    await loadItems(region)
      .then(({ data }) => {
        dispatch(setItems(data));
      })
      .catch(() => {
        dispatch(setLoaded(true));
      });
  };
};

/**
 * Подгружает `.items`, добавляет к текущим.
 * @param {string} region - регион сайта
 */
const loadMoreNotifications = (region) => {
  return async (dispatch, getState) => {
    const items = getState().profileNotifications.items;

    await loadItems(region, items.length)
      .then(({ data }) => {
        dispatch(appendItems(data.items));
      })
      .catch(() => {});
  };
};

/**
 * Отправляет запрос на отметку уведомления прочитанным.  
 * Добавляет ему флаг `.show` в сторе.
 * @param {string} region - регион сайта
 * @param {string} id - id уведомления
 * 
 */
const markNotificationRead = (region, id) => {
  return async (dispatch, getState) => {
    const { items } = getState().profileNotifications;

    if (!items.find(item => (item.id === id) && !item.show)) return null;

    await markRead(region, id)
      .then(() => {
        dispatch(setReadItem({
          items: items.map(item => {
            return (item.id === id)
              ? { ...item, show: true }
              : item;
          }),
        }));
      })
  };
};

/**
 * @returns {Object[]} вернёт `items` без флага `.show`
 */
const getUnreadNotifications = (state) => {
  return state.profileNotifications.items.filter(item => !item.show);
}

export {
  profileNotificationsSlice as default,
  loadNotifications,
  loadMoreNotifications,
  markNotificationRead,
  getUnreadNotifications,
  setLoaded,
  deferRemind as deferNotificationsRemind,
};
