import { request } from "./api";

const url = {
  load: '/lk/notification',
  mark: (id) => `/lk/notification/${id}/show`,
};

/**
 * Запрос на загрузку уведомлений. Вернёт api/request (axios).
 * @param {string} region - текущий регион
 * @param {number=} offset - смещение выборки (количество уже загруженных уведомлений)
*/
const loadItems = async (region, offset = 0) => {
  return request(url.load, {
    params: {
      offset,
      region,
    },
    withCredentials: true,
  });
};

/**
 * Запрос на отметку уведомления прочитанным. Вернёт api/request (axios).
 * @param {string} region - текущий регион
 * @param {string} id - id уведомления
 */
const markRead = async(region, id) => {
  return request(url.mark(id), {
    params: {
      region,
    },
    withCredentials: true,
  });

};

export { loadItems, markRead };
