import { request } from "./api";

const url = {
  load: '/lk/special/',
  mark: (id) => `/lk/special/${id}/show`,
};

/**
 * Запрос на загрузку акций. Вернёт api/request (axios).
 * @param {string} region - текущий регион
 * @param {string} tab - вкладка для которой загружаются акции
 * @param {number} offset - смещение выборки (количество уже загруженных акций)
 */
const loadItems = async (region, tab, offset = 0) => {
  return request(url.load, {
    params: {
      region,
      offset,
      history: (tab === 'archive') ? '1' : null,
    },
    withCredentials: true,
  });
};

/**
 * Запрос на отметку акции прочитанной. Вернёт api/request (axios).
 * @param {string} region - текущий регион
 * @param {string} id - id отмечаемой акции
 */
const markRead = async (region, id) => {
  return request(url.mark(id), {
    params: {
      region,
    },
    withCredentials: true,
  });
};

export { loadItems, markRead };
