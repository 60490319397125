import React from 'react';
import { useSelector } from "react-redux";
import { useParams, useHistory, Redirect } from "react-router-dom";

/**
 * Компонент для профилирования пользователя. Для использования необходимо обернуть
 * компонент передаваемый в Route компонент (react-router)
 * @param props пропсы компонента
 * @param {import("react").ReactNode} props.component реакт компонент
 */
const Profiler = ({
  children,
  ...props
}) => {
  const { region } = useParams();
  const profileData = useSelector((state) => state.profile);

  if (profileData.region && region && profileData.region !== region) {
    return <Redirect to={props.match.url.replace(region, profileData.region)} />
  }

  const component = React.Children.only(children);
  
  return React.createElement(
    component.type,
    {...component.pprops, ...props},
    component.children,
  );
}

export default Profiler;
