import { createSlice } from "@reduxjs/toolkit";

/**
 * Стор переключения мобильного меню и его вкладок. Используется в `layouts/Menu/Box`
 */
const menuBoxSlice = createSlice({
  name: 'menuBox',
  initialState: {
    tab: null,
  },
  reducers: {
    /**
     * Открывает мобильное меню на указанной в `payload` вкладке или переключает на неё.
     * Закроет меню если передать текущую вкладку или `null, false` итд.
     */
    toggleMenuBox: (state, action) => {
      return {
        ...state,
        tab: (action.payload && action.payload !== state.tab) ? action.payload : null,
      };
    },
    /**
     * Закрывает мобильное меню.
     */
    closeMenuBox: (state) => {
      return {
        ...state,
        tab: null,
      };
    },
  },
});

export const { toggleMenuBox, closeMenuBox } =  menuBoxSlice.actions;

export default menuBoxSlice;
