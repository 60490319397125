import { createSlice } from "@reduxjs/toolkit";
import { request } from '../api/api';

const initialValues = {
  posts: {
    items: [{
      code: "default",
      ar: {
        title: "",
        group: "",
        preview_text: "",
      },
      en: {
        title: "",
        group: "",
        preview_text: "",
      }
    }]
  },
  topPosts: {},
}

const postSlice = createSlice({
  name: 'post',
  initialState: initialValues,
  reducers: {
    setPosts: (state, action) => {
      const posts = action.payload;
      return { ...state, posts }
    },
    setMorePosts: (state, action) => {
      const morePosts = action.payload;

      state.posts.items = [ ...state.posts.items, ...morePosts]
    },
    setTopPosts: (state, action) => {
      state.topPosts = action.payload;
    }
  }
});

export default postSlice;

const { setPosts, setMorePosts, setTopPosts } = postSlice.actions;

export const getPosts = (state) => state.post.posts;
export const getTopPosts = (state) => state.post.topPosts;

export const loadPosts = ({
  group,
  region,
  offset,
  onSuccess,
}) => (dispatch) => {
  request({
    url: 'post/',
    method: 'GET',
    params: {
      group,
      region,
      offset,
    }
  })
  .then((res) => {
    if (offset) {
      return dispatch(setMorePosts(res.data.items))
    }
    if (onSuccess) {
      onSuccess();
    }

    dispatch(setPosts(res.data))
  })
  .catch((err) => {
    console.error(err);
    const data = { items: [] };
    dispatch(setPosts(data))
  })
}

export const loadMainPosts = ({ group, region }) => (dispatch) => {
  request({
    method: 'GET',
    url: 'post/main',
    params: {
      group,
      region,
    }
  })
  .then((res) => {
    dispatch(setPosts(res.data))
  })
  .catch(console.error);
}

export const loadTopPosts = ({ region }) => (dispatch) => {
  request({
    method: 'GET',
    url: 'post/top',
    params: {
      region,
    }
  })
  .then((res) => {
    dispatch(setTopPosts(res.data));
  })
  .catch(console.error)
}
