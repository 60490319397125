import { request, requestRoot } from "./api";

export const getToken = async () => {
  return requestRoot({
    method: "GET",
    url: '/sanctum/csrf-cookie',
  })
}

export const authorization = async (value) => {
	return requestRoot({
		method: "POST",
		url: 'api/lk/auth/',
		data: value,
	});
}

export const registration = async (value) => {
	const data = {
		...value,
		car_info: value.cars.map(car => car.registrationNumber),
		subscribe: value.subscribe ? 1 : 0
	}
	delete data.cars
	return requestRoot({
		method: "POST",
		url: 'api/lk/register',
		data: data,
  })
}

export const verificationEmail = async (value) => {
	return requestRoot({
		method: "POST",
		url: 'api/lk/confirm',
		data: {verification_code: value},
  })
}

export const confirmEmail = async (value) => {
	return request({
		method: "POST",
		url: '/lk/confirm',
		data: {
			email: value.email,
			region: value.region
		},
  })
}

export const profileInformation = async () => {
	 return requestRoot({
		method: "GET",
		url: '/api/lk/profile',
  })
}

export const profileUpdate = async (value) => {
	return requestRoot({
		method: "POST",
		url: '/api/lk/profile',
		data: {...value,
			name: value.first_name,
			car_info: value.car_info.filter(Boolean)
		},
  })
}

export const changePassword = (value) => {
	return request({
		method: "POST",
		url: `lk/profile/password`,
		data: value,
	})
}

export const changeEmail = (value) => {
	return requestRoot({
		method: "POST",
		url: `api/lk/profile/email`,
		data: value,
	})
}

export const restorePassword = (value) => {
	return request({
		method: "POST",
		url: `lk/restore`,
		data: value,
	})
}

export const logout = () => {
  return request({
    method: "GET",
    url: "lk/logout",
  })
}
