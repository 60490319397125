import { createSlice } from "@reduxjs/toolkit";
import { loadItems, markRead } from "../api/profileEventsApi";
import { NOTIFICATIONS_CHECK_INTERVAL as interval } from "../configs/global";

const initialState = {
  isLoaded: false,
  tabs: {
    new: {
      items: [],
      offset: 0,
      total: 0,
      // timestamp на котором сработает напоминание о непрочитанных акциях
      remind: Date.now() + interval * 1000,
    },
    archive: {
      items: [],
      offset: 0,
      total: 0,
    },
  },
};

const profileEventsSlice = createSlice({
  name: 'profileEvents',
  initialState,
  reducers: {
    setItems: (state, action) => {
      const { tab, items, offset, total } = action.payload;

      if (!state.tabs[tab] || !Array.isArray(items)) return state;

      return {
        ...state,
        tabs: {
          ...state.tabs,
          [tab]: {
            ...state.tabs[tab],
            items,
            total: (typeof total !== 'number')
              ? state.tabs[tab].total
              : total,
            offset: (typeof offset !== 'number')
              ? state.tabs[tab].items.length + items.length
              : offset,
            remind: Date.now() + interval * 1000,
          },
        },
      };
    },
    setLoaded: (state, action) => {
      return {
        ...state,
        isLoaded: action.payload,
      }
    },
    deferRemind: (state, action) => {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          new: {
            ...state.tabs.new,
            remind: Date.now() + interval * 1000,
          },
        },
      };
    },
  },
});

const { setItems, setLoaded, deferRemind } = profileEventsSlice.actions;

/**
 * Загружает новые `[tab].items`, заменяет текущие.
 * @param {string} region - регион сайта
 * @param {string} tab - ключ из `state.tabs`
 */
const loadEvents = (region, tab = 'new') => {
  return async (dispatch) => {
    await loadItems(region, tab)
      .then(({ data }) => {
        dispatch(setItems({
          tab,
          items: data.items,
          total: data.total,
        }));
        dispatch(setLoaded(true));
      })
      .catch(() => {
        dispatch(setLoaded(true));
      });
  };
};

/**
 * Подгружает `[tab].items`, добавляет к текущим.
 * @param {string} region - регион сайта
 * @param {string} tab - ключ из `state.tabs`
 */
const loadMoreEvents = (region, tab) => {
  return async (dispatch, getState = () => {}) => {
    const { items, offset } = getState().profileEvents?.tabs?.[tab];

    await loadItems(region, tab, offset)
      .then(({ data }) => {
        dispatch(setItems({
          tab,
          items: items.concat(data.items),
          total: data.total,
        }));
      })
      .catch(() => {});
  };
};

/**
 * Отправляет запрос на отметку карточки прочитанной, удаляет её из `tabs.new.items`.  
 * Подгружает следующие при удалении последней.
 * @param {string} region - регион сайта
 * @param {string} id - id карточки
 */
const markEventRead = (region, id) => {
  return async (dispatch, getState = () => {}) => {
    const { items, offset } = getState().profileEvents.tabs.new;

    if (!items.find(item => item.id === id)) return null;

    await markRead(region, id)
      .then(res => {
        if (!res.data.success) throw new Error();

        dispatch(setItems({
          tab: 'new',
          items: items.filter(item => id !== item.id),
          offset,
        }));

        if (items.length <= 1) dispatch(loadEvents(region));
      })
      .catch(() => {});
  };
};

/**
 * @returns {Object[]} вернёт карточки вкладки 'new' - `tabs.new.items`
 */
const getUnreadEvents = (state) => {
  return state.profileEvents.tabs.new.items;
};

export {
  profileEventsSlice as default,
  loadEvents,
  loadMoreEvents,
  markEventRead,
  getUnreadEvents,
  setLoaded,
  deferRemind as deferEventsRemind,
};
