import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import articleGroupSlice from "../features/articleGroupSlice";
import postSlice from "../features/postSlice";
import previewReducer from "../features/previewReducer";
import profileEventsSlice from "../features/profileEventsSlice";
import profileNotificationsSlice from "../features/profileNotificationsSlice";
import menuBoxSlice from "../features/menuBoxSlice";
import profileDataSlice from "../features/profileDataSlice";

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
});

export const store = configureStore({
  reducer: {
    post: postSlice.reducer,
    articleGroup: articleGroupSlice.reducer,
    editorPreview: previewReducer.reducer,
    profileEvents: profileEventsSlice.reducer,
    profileNotifications: profileNotificationsSlice.reducer,
    menuBox: menuBoxSlice.reducer,
    profile: profileDataSlice.reducer,
  },
  middleware: customizedMiddleware,
});
