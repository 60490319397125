import React, {useContext} from 'react';
import { reactAppUrl } from './configs/processEnv';
import { LanguageContext } from "./contexts/LanguageContext";
import { Route, Switch, Redirect } from "react-router-dom";

import lifestyleEventJSON from "./content/lifestyle-event.json";
import event3JSON from "./content/event-3.json";
import event4JSON from "./content/event-4.json";
import useCheckAuthorization from "./hooks/useStorageProfile";
import Profiler from "./components/Profiler";

const Main = React.lazy(() => import('./pages/Main/Main'));
const Event = React.lazy(() => import('./pages/Event/Event'));
const Regions = React.lazy(() => import('./pages/Regions/Regions'));
const DetailArticle = React.lazy(() => import('./pages/DetailArticle/DetailArticle'));
const TalkTok = React.lazy(() => import('./pages/TalkTok/TalkTok'));
const ArticlesPage = React.lazy(() => import('./pages/ArticlesPage/ArticlesPage'));
const Journey_2020 = React.lazy(() => import('./pages/YourJourney/2020'));
const Journey_2021 = React.lazy(() => import('./pages/YourJourney/2021'));
const Journey_2022 = React.lazy(() => import('./pages/YourJourney/2022'));
const ThankYou = React.lazy(() => import('./pages/ThankYou/ThankYou'));
const Page404 = React.lazy(() => import("./pages/Page404/Page404"));
const Profile = React.lazy(() => import("./pages/Profile"));
const Authorization = React.lazy(() => import('./pages/Authorization/Authorization'));

const PrivateRoute = ({ children, ...rest}) => {
  const { lang } = useContext(LanguageContext);
  const langFromUrl = lang === 'ar' ? '' : '/' + lang;
  const isAuth = useCheckAuthorization();

  return (
    <Route
      {...rest}
      component={(props) => {
        return isAuth
          ? children
          : <Redirect to={`${reactAppUrl}${props.match.params.region}${langFromUrl}/authorization`} />
      }}
    />
  )
}

const Routes = () => {
  const { lang } = useContext(LanguageContext);
  const langFromUrl = lang === 'ar' ? '' : '/' + lang;

  return (
    <React.Suspense fallback={<div></div>}>
      <Switch>
        <Route exact path={`${reactAppUrl}`}>
          {(props) => (
            <Profiler {...props}>
              <Regions />
            </Profiler>
          )}
        </Route>
        <Route
          exact
          path={`${reactAppUrl}event${langFromUrl}`}
        >
          {(props) => (
            <Profiler {...props}>
              <>
                <Redirect to={`${reactAppUrl}event4${langFromUrl}`} />
                <Event data={event3JSON} {...props} />
              </>
            </Profiler>
          )}
        </Route>
        <Route
          path={`${reactAppUrl}event${langFromUrl}/life-style`}
        >
          {(props) => (
            <Profiler {...props}>
              <>
                <Redirect to={`${reactAppUrl}event4${langFromUrl}`} />
                <Event data={lifestyleEventJSON} {...props} />
              </>
            </Profiler>
          )}
        </Route>
        <Route
          exact
          path={`${reactAppUrl}event3${langFromUrl}`}
        >
          {(props) => (
            <Profiler {...props}>
              <Event data={event3JSON} {...props} />
            </Profiler>
          )}
        </Route>
        <Route
          exact
          path={`${reactAppUrl}event4${langFromUrl}`}
        >
          {(props) => (
            <Profiler {...props}>
              <Event data={event4JSON} {...props} />
            </Profiler>
          )}
        </Route>
        <Route exact path="/page404">
          {(props) => (
            <Profiler {...props}>
              <Page404 />
            </Profiler>
          )}
        </Route>
        <Route
          path={`${reactAppUrl}:region${langFromUrl}`}
          exact
        >
          {(props) => (
            <Profiler {...props}>
              <Main />
            </Profiler>
          )}
        </Route>
        <Route
          path={`${reactAppUrl}:region${langFromUrl}/exclusive-stories`}
          exact
        >
          {(props) => (
            <Profiler {...props}>
              <ArticlesPage />
            </Profiler>
          )}
        </Route>
        <Route
          path={`${reactAppUrl}:region${langFromUrl}/exclusive-stories/detail/:bulletinId`}
          exact
        >
          {(props) => (
            <Profiler {...props}>
              <DetailArticle />
            </Profiler>
          )}
        </Route>
        <Route
          path={`${reactAppUrl}:region${langFromUrl}/talk-tok`}
          exact
        >
          {(props) => (
            <Profiler {...props}>
              <TalkTok />
            </Profiler>
          )}
        </Route>
        <Route
          path={`${reactAppUrl}2022/:region${langFromUrl}`}
          exact
        >
          {(props) => (
            <Profiler {...props}>
              <Journey_2022 />
            </Profiler>
          )}
        </Route>
        <Route
          path={`${reactAppUrl}2021/:region${langFromUrl}`}
          component={Journey_2021}
          exact
        >
          {(props) => (
            <Profiler {...props}>
              <Journey_2021 />
            </Profiler>
          )}
        </Route>
        <Route
          path={`${reactAppUrl}2020/:region${langFromUrl}`}
          component={Journey_2020}
          exact
        >
          {(props) => (
            <Profiler {...props}>
              <Journey_2020 />
            </Profiler>
          )}
        </Route>
        <Route
          path={`${reactAppUrl}:region${langFromUrl}/thank_you`}
          exact
        >
          {(props) => (
            <Profiler {...props}>
              <ThankYou />
            </Profiler>
          )}
        </Route>
        <Route
          path={`${reactAppUrl}:region${langFromUrl}/authorization`}
          exact
        >
          {(props) => (
            <Profiler {...props}>
              <Authorization />
            </Profiler>
          )}
        </Route>
        <PrivateRoute path={`${reactAppUrl}:region${langFromUrl}/profile`}>
          <Profile />
        </PrivateRoute>
        <Route exact path="*">
          {(props) => (
            <Profiler {...props}>
              <Page404 />
            </Profiler>
          )}
        </Route>
      </Switch>
    </React.Suspense>
  )
}

export default Routes;

