import React, { createContext, Component } from "react";
import { Helmet } from "react-helmet";
import { defaultRegions, usedLangs } from "../content/languages.json";
import { reactAppUrl } from '../configs/processEnv';

import translate from "../content/translate.json";

export const LanguageContext = createContext("en");

class LanguageProvider extends Component {
  constructor(props) {
    super(props);
    const splitURL = window.location.pathname.split("/");
    this.translate = translate;
    let lang = !splitURL.includes("en") ? "ar" : "en";
    let dir = lang === "ar" ? "rtl" : "ltr";

    this.html = document.querySelector("html");
    this.html.setAttribute("lang", lang);
    localStorage.setItem("lang", lang);

    this.state = {
      lang,
      translate: translate[lang],
      dir
    };

    window.lang = lang;
  }

  checkLang() {
    // const splitURL = window.location.pathname.split("/");
    // const lang = !splitURL.includes("en") ? "ar" : "en";
    // const dir = lang === "ar" ? "rtl" : "";
    // if (this.state.lang !== lang) {
    //   const translate = this.translate[lang];
    //   this.setState({ lang, translate, dir });
    // }
  }

  toggleLang = (lang, push) => {
    if (window.lang === lang) return false;

    window.lang = lang;

    let newUrl = window.location.pathname;
    const langInUrl = usedLangs.find((lang) => newUrl.includes(lang));
    if (langInUrl && this.lang1 === lang) {
      newUrl = newUrl.replace(`/${langInUrl}`, "");
    }

    newUrl = newUrl.split("/").reduce((acc, path, n) => {
      if (path === "") return acc;
      if (defaultRegions.includes(path) && lang !== "ar") return `${acc}/${path}/${lang}`;
      return `${acc}/${path}`;
    }, "");

    newUrl = newUrl.replace(`/${langInUrl}`, lang === "ar" ? "" : `/${lang}`);

    if (push) {
      push(newUrl);
    }
    
    let dir = lang === "ar" || "kr" ? "rtl" : "ltr";
    const translate = this.translate[lang];
    this.setState({lang, translate, dir});
  };

  getTranslate = (key) => {
    let { translate } = this.state;
    if (key === undefined || key === null) return "";
    if (typeof key === "boolean") return key;
    const keys = key.split(".");
    const keysLength = keys.length;
    if (keysLength < 1) return key;
    for (let index = 0; index < keysLength; index++) {
      if (!translate?.[keys[index]]) return key;
      translate = translate[keys[index]];
    }
    return translate;
  };

  render() {
    return (
      <LanguageContext.Provider value={{
        ...this.state,
        toggleLang: this.toggleLang.bind(this),
        getTranslate: this.getTranslate.bind(this),
        checkLang: this.checkLang.bind(this)
      }}>
        <Helmet>
          <title>{this.getTranslate('head.title')}</title>
        </Helmet>
        {this.props.children}
      </LanguageContext.Provider>
    )
  }
}

export default LanguageProvider;
