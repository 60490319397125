import { createSlice } from "@reduxjs/toolkit";
import {logout, profileInformation} from '../api/profileApi'
import { setStorageProfileAuth } from "../hooks/useStorageProfile";

const initialValues = {
  isLoaded: false,
}

const profileDataSlice = createSlice({
  name: 'profile',
  initialState: initialValues,
  reducers: {
    setPorfileData: (state, action) => {
      return {...state, ...action.payload};
    },
    setProfileLoaded: (state, action) => {
      return {...state, isLoaded: action.payload};
    },
    clearProfile: () => {
      return initialValues;
    }
  }
});

export const { setPorfileData, setProfileLoaded, clearProfile } = profileDataSlice.actions

const loadProfile = () => {
  return async (dispatch) => {
    await profileInformation()
      .then((values) => {
        dispatch(setPorfileData(
          values.data
        ));
      })
      .catch(() => {
        setStorageProfileAuth(false);
      })
      .finally(() => {
        dispatch(setProfileLoaded(true));
      });
  };
};

const logoutProfile = () => {
  return async (dispatch) => {
    await logout()
      .then(() => {
        setStorageProfileAuth(false);
        dispatch(clearProfile());
      })
  }
}

export {
  profileDataSlice as default,
  loadProfile,
  logoutProfile,
};
